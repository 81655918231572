import React from "react"
import { colors, fontSizes } from "../../structure/theme"
import Step from "./step.component"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Swiper from "../../../components/swiper.component"
import { Trans, useTranslation } from "../../translation/translate.component"

const TextPrimary = styled.span`
  color: ${colors(`primary`)};
  font-weight: 900;
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Reserve = styled.span`
  font-size: ${fontSizes(`medium`)};
  font-weight: 400;
`

export default function ThreeSteps () {
  const { t } = useTranslation()
  const { step1, step2, step3 } = useStaticQuery(query)

  return (
    <Swiper
      NoSwiperWrapper={Content}
      bulletType="full"
      arrowType="far"
      foregroundColor={APP_CONFIG.theme.modules.navigation.footer.backgroundColor}
    >
      <Step nb={1} img={step1}>
        <Trans
          t={t}
          i18nKey="common:information.section_three_steps_step1"
          components={{
            primary: <TextPrimary />,
          }}
        />
      </Step>
      <Step nb={2} img={step2}>
        <Trans
          t={t}
          i18nKey="common:information.section_three_steps_step2"
          components={{
            primary: <TextPrimary />,
          }}
        />
      </Step>
      <Step nb={3} img={step3}>
        <Trans
          t={t}
          i18nKey="common:information.section_three_steps_step3"
          components={{
            primary: <TextPrimary />,
            reserve: <Reserve />,
          }}
        />
      </Step>
    </Swiper>
  )
}

ThreeSteps.propTypes = {}

const query = graphql`
  query {
    step1: file(name: { eq: "step1" }) {
      ...responsiveFileImage600
    }
    step2: file(name: { eq: "step2" }) {
      ...responsiveFileImage600
    }
    step3: file(name: { eq: "step3" }) {
      ...responsiveFileImage600
    }
  }
`
