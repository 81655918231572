import React from "react"
import { percent, fontSizes } from "../../structure/theme"
import { Image } from "../../../components/images.component"
import PropTypes from "prop-types"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { useTranslation } from "../../translation/translate.component"
import { flexCenter } from "../../structure/theme/styles.component"

const Number = styled.div`
  position: absolute;
  top: -10px;
  left: 47px;
  ${flexCenter};
  margin-bottom: 16px;
  margin-top: 16px;
  font-weight: 900;
  font-size: ${fontSizes(`x_large`)};
  color: black;
  background-color: white;
  border-radius: 50%;
  border: 2px solid black;
  height: 39px;
  width: 39px;
`

const ImgContainer = styled.div`
  text-align: center;
  position: relative;

  ${Image} {
    width: 195px;
    height: auto;
    margin-bottom: 17px;
  }
`

const Text = styled.div`
  width: 100%;

  ${breakpoint(`small`)`
    padding: 0 30px;
  `};
`

const Wrapper = styled.div`
  ${flexCenter};
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  height: auto;
  text-align: center;

  ${breakpoint(`small`)`
    width: ${percent(1 / 3)};
    padding-bottom: 0;
    height: 100%;
  `};
`

export default function Step ({ nb, img, children }) {
  const { t } = useTranslation()

  return (
    <Wrapper data-testid={arguments[0][`data-testid`] || undefined}>
      <ImgContainer>
        <Image
          file={img}
          alt={t(`common:information.section_three_steps_step_image_alt`, {
            number: nb,
          })}
        />
        <Number>{nb}</Number>
      </ImgContainer>
      <Text>{children}</Text>
    </Wrapper>
  )
}

Step.propTypes = {
  nb: PropTypes.number.isRequired,
  img: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
}
